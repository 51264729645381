import React from 'react';
import { PropTypes } from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const Seo = ({ type, slug, title, desc, image }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          sanitySeo(_id: { eq: "seo" }) {
            seoTitle
            seoDescription
            seoImage {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      `}
      render={(data) => {
        const defaultSeoData = data.sanitySeo;
        const seoData = {
          title: title ? title : defaultSeoData.seoTitle,
          description: desc ? desc : defaultSeoData.seoDescription,
          image: image
            ? image
            : defaultSeoData.seoImage.asset.gatsbyImageData.src,
          type: type ? type : 'website',
          slug: slug ? slug : '/',
        };
        const pageUrl = 'https://dugnadservice.no/';
        return (
          <Helmet
            htmlAttributes={{
              lang: 'no-nb',
            }}
            title={seoData.title}
            description={seoData.description}
            titleTemplate={seoData.title ? `%s` : null}
            meta={[
              {
                property: `og:title`,
                content: seoData.title,
              },
              {
                property: `og:description`,
                content: seoData.description,
              },
              {
                property: `og:type`,
                content: seoData.type,
              },
              {
                property: `og:image`,
                content: seoData.image,
              },
              {
                property: `og:url`,
                content: `${pageUrl}${seoData.slug}`,
              },
            ]}
          >
            <meta name="og:title" content={seoData.title} />
            <meta name="og:description" content={seoData.description} />
            <meta name="description" content={seoData.description} />
            <meta name="og:type" content={seoData.type} />
            <meta name="og:image" content={seoData.image} />
            <meta name="og:url" content={`${pageUrl}${seoData.slug}`} />
          </Helmet>
        );
      }}
    />
  );
};

Seo.propTypes = {
  type: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

Seo.defaultProps = {
  type: null,
  slug: null,
  title: null,
  description: null,
  image: null,
};

export default Seo;
