import React, { useState } from 'react';
import * as styles from './Menu.module.scss';
import classNames from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import logo from '../../images/logo.png';
import logoNeg from '../../images/logo_neg.png';
import { CloseOutlined, MenuOutlined } from '@material-ui/icons';

const Menu = ({ theme }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <nav className={classNames(styles.menu, styles[theme])}>
      <div className={styles.logo}>
        <Link to="/">
          {theme === 'light' ? (
            <img src={logoNeg} alt="Logo" />
          ) : (
            <img src={logo} alt="Logo" />
          )}
        </Link>
      </div>
      <button
        className={styles.menuOpen}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <MenuOutlined className={styles.icon} />
      </button>
      <div
        className={classNames(styles.panel, {
          [styles.open]: menuOpen === true,
        })}
      >
        <button
          className={styles.menuClose}
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <CloseOutlined className={styles.icon} />
        </button>
        <div className={styles.links}>
          <div className={styles.link}>
            <Link to="/">Hjem</Link>
          </div>
          <div className={styles.link}>
            <Link to="/produkter">Produkter</Link>
          </div>
          <div className={styles.link}>
            <Link to="/bestill">Bestill</Link>
          </div>
          <div className={styles.link}>
            <Link to="/registrer">Registrer deg</Link>
          </div>
          <div className={styles.link}>
            <Link to="/spons">Spons</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

Menu.propTypes = {
  theme: PropTypes.string,
};

Menu.defaultProps = {
  theme: 'light',
};

export default Menu;
