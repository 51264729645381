// extracted by mini-css-extract-plugin
export var dark = "Menu-module--dark--V7MMl";
export var light = "Menu-module--light--RAMsM";
export var link = "Menu-module--link--y6C+y";
export var links = "Menu-module--links--ql1JZ";
export var logo = "Menu-module--logo--+p+rk";
export var menu = "Menu-module--menu--aOZOi";
export var menuClose = "Menu-module--menuClose--8ldWY";
export var menuOpen = "Menu-module--menuOpen--feNm0";
export var open = "Menu-module--open--pIJWx";
export var panel = "Menu-module--panel--cT9DK";