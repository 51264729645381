import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import * as styles from './Footer.module.scss';

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          sanityTexts(_id: { eq: "texts" }) {
            _rawFooter(resolveReferences: { maxDepth: 10 })
          }
        }
      `}
      render={(data) => {
        return (
          <div className={styles.footer}>
            <div className={styles.text}>
              <PortableText blocks={data.sanityTexts._rawFooter} />
            </div>
          </div>
        );
      }}
    />
  );
};

export default Footer;
