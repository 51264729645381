import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Layout.module.scss';
import classNames from 'classnames';
import Menu from '../Menu/Menu';
import Seo from '../Seo/Seo';
import Footer from '../Footer/Footer';

const Layout = ({ title, hideFooter, center, theme, menuTheme, children }) => {
  return (
    <main
      className={classNames(styles.layout, styles[theme], {
        [styles.center]: center,
      })}
    >
      <Seo title={title} />
      <Menu theme={menuTheme} />
      {children}
      {hideFooter !== true && <Footer />}
    </main>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  hideFooter: PropTypes.bool,
  center: PropTypes.bool,
  theme: PropTypes.string,
  menuTheme: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  title: null,
  hideFooter: false,
  center: false,
  theme: 'light',
  menuTheme: 'light',
  children: null,
};

export default Layout;
